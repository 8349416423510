<template>
    <div class="main" ref="main" v-loading.fullscreen="eleLoading">
        <!-- 顶部导航 -->
        <div class="nav" ref="nav">
            <!-- <el-breadcrumb separator-class="el-icon-arrow-right" v-if="elBreadcrumb.length">
                <el-breadcrumb-item @click.native="backCase(1)">{{ elBreadcrumb[0].title }}</el-breadcrumb-item>
                <el-breadcrumb-item @click.native="backCase(2)">{{ elBreadcrumb[1].title }}</el-breadcrumb-item>
                <el-breadcrumb-item class="last">{{ elBreadcrumb[2].title }}</el-breadcrumb-item>
            </el-breadcrumb> -->
            <div v-if="elBreadcrumb.length" class="breadcrumb">
                <div @click.native="backCase(1)">{{ elBreadcrumb[0].title }}</div>
                <div class="arrowRight">
                    <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                </div>
                <div @click.native="backCase(2)">{{ elBreadcrumb[1].title }}</div>
                <div class="arrowRight">
                    <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                </div>
                <div>{{ elBreadcrumb[2].title }}</div>
            </div>

            <!-- 可拖动 -->
            <div class="swiper-container" id="nav_sw">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="el in navList" :key="el.id" @click="navClick(el)">
                        <img v-if="el.logo" :src="el.logo" class="logo" />
                        <p v-else>{{ el.title }}</p>
                        <span v-if="el.logo">{{ el.title }}</span>
                        <img :src="el.qrcode" class="QRcode" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 按钮组 -->
        <div class="bts">
            <button @click="goLink(obj.project_url)">了解官网&nbsp;&nbsp;&nbsp;</button>
            <button @click="$refs['vuform'].showTcbs()">我要开发&nbsp;&nbsp;&nbsp;</button>
        </div>
        <!-- 弹出层 -->
        <VuForm ref="vuform" page="客户案例详情页 "></VuForm>
        <div class="bigImg">
            <img :src="obj.big_image ? obj.big_image : 'https://iv.okvu.cn/vugw/img/vu818khalxq.jpg'" />
        </div>
        <!-- 富文本 -->
        <div style="text-align: left" class="editor-p" v-html="obj.ueditorData"></div>
        <!-- 定价 -->
        <div class="fixPrice">
            <div class="swiper-container" id="fixPrice_sw">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in fixPriceList" :key="item.id" @click="">
                        <!-- 右上小提示 -->
                        <div class="tip" v-show="item.hot_status">
                            <div>{{ item.hot }}</div>
                        </div>
                        <div class="version-one">
                            <div>
                                <h3>{{ item.name }}</h3>
                                <div>{{ item.reason }}</div>
                            </div>
                            <div>
                                <p class="price">
                                    <span>{{ item.oldprice }} </span>
                                    <span> 优惠后</span>
                                    <span>{{ item.newprice }}</span>
                                </p>
                                <div>
                                    <el-row>
                                        <button @click="$router.push('/fixPriceDetails?id=' + item.id)">
                                            <span
                                                class="loding"
                                                :style="{
                                                    width: isMout
                                                        ? `${((item.all_time - item.much_time) / item.all_time) * 100}%`
                                                        : '',
                                                }"
                                                v-show="item.hot_status"
                                            ></span>
                                            <template v-if="item.hot_status"
                                                >活动截止{{ item.hot_end_time }}，只剩{{ item.much_time }}天</template
                                            >
                                            <template v-else>了解详情</template>
                                        </button>
                                    </el-row>
                                </div>
                            </div>
                        </div>
                        <div class="version-two">
                            <div style="font-weight: 600">包含功能:</div>
                            <div>
                                <div class="com" v-for="el in item.content.split('-')" :key="el">
                                    <svg
                                        width="1em"
                                        height="1em"
                                        style="color: blue; margin-right: 10px"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="ones-icon ones-icon-non-scaling-stroke oac-text-green oac-inline-block oac-mr-2 oac-text-16"
                                        stroke-width="2"
                                    >
                                        <path
                                            d="m13.938 4-7.331 8.195L3 8.5"
                                            stroke="currentColor"
                                            stroke-linecap="square"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    <span>{{ el }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 50px"></div>
    </div>
</template>

<script>
import VuForm from "../../TheContact/vuForm.vue";
import axios from "axios";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
    components: { VuForm },
    data() {
        return {
            navList: [],
            elBreadcrumb: [],
            obj: {},
            eleLoading: true,
            nav_sw: {},
            fixPriceList: [],
            fixPrice_sw: {},
            // 控制定价内的按钮动画
            isMout: false,
        };
    },
    async created() {
        setTimeout(() => {
            this.eleLoading = false;
        }, 1000);
        await this.loading();
        await this.getBrother();
        await this.getFixPrice();
        this.$nextTick(() => {
            this.nav_sw = new Swiper("#nav_sw", {
                slidesPerView: "auto",
                mousewheel: true,
                spaceBetween: 10,
                freeMode: true,
            });
            this.fixPrice_sw = new Swiper("#fixPrice_sw", {
                slidesPerView: "auto",
                mousewheel: true,
                spaceBetween: 20,
                freeMode: true,
            });
        });
    },
    mounted() {
        // 绑定滚动事件
        window.addEventListener("scroll", this.scroll);
    },
    beforeDestroy() {
        // 解绑
        window.removeEventListener("scroll", this.scroll);
    },
    methods: {
        async getData(id = this.$route.params.id) {
            let { data } = await axios.get("/api/project/search_list_id", { params: { id } });
            return data;
        },
        // 获取所有兄弟案例
        async getBrother() {
            let { data } = await axios.get("/api/project/secondwithlist", { params: { id: this.obj.category_id } });
            this.navList = data.filter(e => e.id != this.obj.id);
        },
        // 获取定价数据
        async getFixPrice() {
            let { data } = await axios.get("/api/price/fshow");
            if (data.length > 18) data = data.slice(0, 18);
            this.fixPriceList = [...data];
        },
        // 加载数据
        async loading() {
            let _this = this;
            // 获取案例的具体详情
            let data1 = await this.getData();
            this.obj = data1;
            // 获取一二级名字
            let { data: data2 } = await axios.get("/api/project/category_client");
            data2.forEach(e => {
                if (e.children) {
                    e.children.forEach(el => {
                        if (el.id == data1.category_id) {
                            _this.elBreadcrumb = [
                                {
                                    title: e.category_title,
                                    id: e.id,
                                },
                                {
                                    title: el.category_title,
                                    id: el.id,
                                },
                                {
                                    title: data1.title,
                                },
                            ];
                        }
                    });
                }
            });
        },
        // 导航栏点击
        async navClick({ id, ueditorData, project_url }) {
            if (id == this.obj.id) return;
            if (!ueditorData) {
                if (project_url.replace("http://", "")) {
                    window.open(project_url);
                }
                return;
            }
            this.eleLoading = true;
            // 获取案例的具体详情
            let data = await this.getData(id);
            this.getBrother();
            this.obj = data;
            this.$set(this.elBreadcrumb, 2, { title: data.title });
            this.$nextTick(() => {
                this.eleLoading = false;
                // 滑动至顶部
                window.scrollTo(0, 0);
            });
        },
        goLink(link) {
            window.location.href = link;
        },
        // 滚动处理
        scroll() {
            let y = window.scrollY;
            let h = this.$refs.main.offsetHeight;
            if (y + 54 > h) {
                // let opacity = (y + 54 - h) / 54;
                // if (y + 54 - h >= 54) opacity = 2;
                // this.$refs.nav.style.opacity = 1 - opacity;
                this.$refs.nav.style.opacity = 0;
            } else {
                let opacity = (h - 54 - y) / 54;
                this.$refs.nav.style.opacity = opacity;
            }
        },
        // 返回客户案例列表
        backCase(level) {
            if (level == 1) {
                this.$router.push(`/case?id=&fuid=${this.elBreadcrumb[0].id}`);
            } else if (level == 2) {
                this.$router.push(`/case?id=${this.elBreadcrumb[1].id}&fuid=${this.elBreadcrumb[0].id}`);
            }
        },
    },
    watch: {
        fixPriceList(newVal) {
            if (newVal.length != 0) {
                this.$nextTick(() => {
                    this.isMout = true;
                });
            }
        },
        "$route.params.id"(newVal) {
            if (this.$route.name == "listDetail") {
                this.navClick({ id: newVal, ueditorData: "占位符，无意义" });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    position: relative;
    // margin-top: 87px;
    .nav {
        z-index: 9;
        height: 54px;
        line-height: 54px;
        width: 100%;
        position: sticky;
        top: 85px;
        left: 0px;
        background: #00000013;
        color: black;
        display: flex;
        align-items: center;
        padding-left: 20px;
        // /deep/.el-breadcrumb__inner {
        //     color: black;
        //     cursor: pointer;
        //     &:hover {
        //         color: #7781f1;
        //     }
        // }
        // .last {
        //     /deep/.el-breadcrumb__inner {
        //         cursor: default;
        //         &:hover {
        //             color: black;
        //         }
        //     }
        // }
        .swiper-container {
            flex: 1;
            overflow-x: clip;
            overflow-y: visible;
            .swiper-slide {
                cursor: pointer;
                width: 100px;
                height: 54px;
                text-align: center;
                // margin: 0 5px;
                position: relative;
                font-size: 14px;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                    .logo {
                        display: none;
                    }
                    span {
                        display: inline;
                    }
                    .QRcode {
                        display: block;
                    }
                }
                span {
                    display: none;
                }
                .logo {
                    width: 90%;
                    height: 90%;
                    object-fit: contain;
                    // max-width: 90%;
                    // max-height: 90%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .QRcode {
                    display: none;
                    position: absolute;
                    top: 110%;
                    left: 0;
                    width: 100%;
                    height: auto;
                    box-shadow: 1px 1px 10px 1px #ccc;
                }
            }
        }
    }
    .bts {
        position: fixed;
        right: 50px;
        bottom: 30px;
        border-radius: 5px;
        overflow: hidden;
        background-color: #7781f1;
        width: 300px;
        height: 45px;
        z-index: 9999;
        &::after {
            content: "";
            width: 1px;
            height: 25px;
            position: absolute;
            left: 50%;
            margin-left: -1px;
            top: 10px;
            background: rgba(255, 49, 52, 0.2);
        }
        button {
            width: 50%;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            line-height: 45px;
            color: white;
            font-size: 20px;
            position: relative;
            cursor: pointer;
            font-weight: 700;
            a:visited {
                color: white;
            }
            &:hover {
                color: black;
                &::after {
                    color: white;
                    background-color: black;
                }
            }
        }
        button::after {
            content: ">";
            font-size: 16px;
            display: inline-block;
            color: #7781f1;
            background-color: white;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            position: absolute;
            z-index: 9;
            top: 50%;
            transform: translate(0, -50%);
            right: 20px;
            line-height: 20px;
        }
    }
    .bigImg {
        min-height: 30vh;
        img {
            width: 100%;
        }
    }
    .fixPrice {
        width: 80%;
        max-width: 1440px;
        min-height: 40vh;
        margin: 50px auto 0;
        border-radius: 10px;
        text-align: left;
        #fixPrice_sw {
            height: 100%;
            padding: 10px;
        }
        .swiper-slide {
            position: relative;
            height: auto;
            width: 30%;
            border-radius: 20px;
            padding: 30px;
            overflow: hidden;
            box-shadow: 0px 0px 21px -15px black;
            min-height: 40vh;

            .tip {
                position: absolute;
                padding: 4px 20px;
                right: -10px;
                top: 0;
                background-color: #f5ebde;
                border-radius: 0px 20px 0px 20px;
                transform: skew(30deg);
                div {
                    color: #a64d00;
                    transform: skew(-30deg);
                }
            }
            .version-one {
                width: 100%;
                word-wrap: break-word;
                h3 {
                    font-size: 24px;
                }
                button {
                    width: 100%;
                    height: 40px;
                    text-align: center;
                    padding: 0 20px;
                    border: 1px solid #7781f1;
                    background-color: rgba(179, 216, 255, 0);
                    color: #7781f1;
                    border-radius: 20px;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                    .loding {
                        position: absolute;
                        display: block;
                        z-index: -1;
                        width: 0%;
                        height: 100%;
                        top: 0px;
                        left: 0px;
                        border-radius: 20px;
                        background-color: #7781f130;
                        transition: 1s;
                    }
                }
            }
            .version-one > div:nth-child(2) {
                width: 100%;
                height: 110px;
                margin-top: 20px;
                .price {
                    span:nth-of-type(1) {
                        font-size: 16px;
                        color: #ccc;
                        // 删除线
                        text-decoration: line-through;
                        margin-right: 5px;
                    }
                    span:nth-of-type(2) {
                        font-size: 12px;
                        color: #7781f1;
                    }
                    span:nth-of-type(3) {
                        font-size: 24px;
                        color: #7781f1;
                        font-weight: bold;
                    }
                }
            }
            .version-two {
                width: 100%;
                padding-top: 10px;
                border-top: 1px solid #f0f0f2;
            }
        }
    }
}
.breadcrumb {
    display: flex;
    align-items: center;
    font-size: 16px;
    div {
        cursor: default;
    }
    .arrowRight {
        // display: none;
        margin: 0 10px;
        width: 16px;
        height: 16px;
        overflow: hidden;
        position: relative;
        img {
            position: absolute;
            top: -81px;
            left: -1131px;
            width: 1200px;
        }
    }
}
</style>
